<template>
  <div class="loginPart">

    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2>Inscription ASPR</h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <div class="alert alert-primary" role="alert">
                L'inscription est réservée aux membres
                de l’association de l’Amicale des Sapeurs Pompiers de Rennes
              </div>
              <CreateUser></CreateUser>
          </div>
                <!--
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Save changes</button>
            </div>
          -->
        </div>
      </div>
    </div>

    <div class="modal fade" id="passRecoverModal" tabindex="-1" role="dialog" aria-labelledby="passRecoverModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2>Récupération de mot de passe</h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              Pour récupérer votre mot de passe, veuillez saisir votre email ci-dessous
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">@</div>
                </div>
                <input type="email" class="form-control" id="inputEmailRecover" aria-describedby="emailHelp" placeholder="Entrer l'email" v-model="user.email" required>
              </div>
          </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
              <button v-if="processing" disabled type="button" class="btn btn-primary"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Envoyer</button>
              <button v-else-if="user.email" type="button" class="btn btn-primary" @click="recover()">Envoyer</button>
            </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col loginDiv card card-login mx-auto text-center bg-dark">
            <div class="card-header mx-auto bg-dark">
                <span> <img src="@/assets/WH2.png" class="w-75" alt="Logo"> </span><br/>
            </div>
            <div class="card-body">
              <form v-on:submit.prevent="authenticate">
                  <div class="input-group form-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fas fa-user"></i></span>
                      </div>
                      <input type="text" name="email" class="form-control" placeholder="e-mail" v-model="user.username" required>
                  </div>

                  <div class="input-group form-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fas fa-key"></i></span>
                      </div>
                      <input type="password" name="password" class="form-control" placeholder="Mot de passe" v-model="user.password" required>
                  </div>
                  <div class="input-group form-group" v-if="passRecovery">
                      <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fas fa-key"></i></span>
                      </div>
                      <input type="password" name="password" class="form-control" placeholder="Retaper le mot de passe" v-model="user.password2" required>
                  </div>

                  <div class="alert alert-danger" role="alert" v-if="loginMessage">
                    {{loginMessage}}
                  </div>
                  <div v-if="passRecovery">
                    <button v-if="processing" name="btn" class="btn btn-outline-danger float-right login_btn">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Réinitialiser
                    </button>
                    <input v-else type="submit" name="btn" value="Réinitialiser" class="btn btn-outline-danger float-right login_btn">
                  </div>
                  <div v-else>
                    <a href="#" class="card-link" style="color:#DDDDDD;" data-toggle="modal" data-target="#passRecoverModal">Mot de passe oublié</a><a href="#" class="card-link" style="color:#DDDDDD;" data-toggle="modal" data-target="#exampleModal">Créer un compte</a>
                    <br/>
                    <button v-if="processing" disabled name="btn" value="Login" class="btn btn-outline-danger float-right login_btn">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Login
                    </button>
                    <input v-else type="submit" name="btn" value="Login" class="btn btn-outline-danger float-right login_btn">
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import CreateUser from '../CreateUser.vue'

export default {
  name: 'Login',
  props: {
    msg: String
  },
  computed: {
    ...mapState({
      accessProtocol: state => state.AppStore.accessProtocol,
      accessIp: state => state.AppStore.accessIp,
      accessPort: state => state.AppStore.accessPort,
      axiosInstanceUnsecure: state => state.AppStore.axiosInstanceUnsecure
    }),
    ...mapGetters({
      parseJwt: 'AppStore/parseJwt'
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'AppStore/updateUser',
      updateTokenExpirationDate: 'AppStore/updateTokenExpirationDate'
    }),
    authenticate(){
      this.processing=true
      if(this.user.username && this.user.password && !this.user.password2){
        this.axiosInstanceUnsecure.post('/auth/signin', this.user).then(response => {
          this.loginMessage = ""
          let authenticatedResult = response.data
          authenticatedResult.accountValidity = authenticatedResult.accountValidity.join('-')
          this.updateUser(authenticatedResult)
          localStorage.userName = authenticatedResult.username
          localStorage.userId = authenticatedResult.userId
          localStorage.jwtToken=authenticatedResult.token
          localStorage.subscriptions=authenticatedResult.subscriptions
          localStorage.roles=authenticatedResult.roles
          localStorage.accountValidity=authenticatedResult.accountValidity
          this.loginMessage = ''

          let parsedToken = this.parseJwt
          localStorage.expirationDate = parsedToken.exp
          this.updateTokenExpirationDate(parsedToken.exp)

          this.$store.dispatch('CommissionStore/loadCommissionsFast')
          this.processing=false

        })
        .catch(e => {
          this.processing=false
          this.loginMessage = 'Utilisateur non-autorisé'
          console.log(e)
        })
      }else if(this.user.password && this.user.password2 && this.passRecovery){
        if(this.user.password===this.user.password2){
          let instance = this.axios.create({
            baseURL: this.accessProtocol+'//'+this.accessIp+':'+this.accessPort+'/FireFighters/aspr/v1',
            //timeout: 1000,
            headers: {'Authorization': 'Bearer '+this.passRecovery}
          })

          instance.post('/auth/reset', this.user).then(response => {
            if(!response)
              console.log(response)
            this.processing=false
            this.$toasted.show("Votre mot de passe a été correctement réinitialisé", {type: 'success'})
            this.$router.push({name:'home'}).catch(()=>{});
            this.passRecovery = null
            this.user.password2 = null
            this.loginMessage = null
          })
          .catch(e => {
            this.processing=false
            if(e.response.status===404){
              this.$toasted.show("L'email entré n'a pas été trouvé", {type: 'error'})
            }else if(e.response.status===403){
              this.$toasted.show("Opération interdite pour l'email choisi", {type: 'error'})
            }else{
              this.$toasted.show("Impossible de réinitialiser le mot de passe "+ e, {type: 'error'})
            }
            console.log(e)
          })
        }else{
          this.loginMessage = 'Les mots de passe ne correspondent pas'
          this.processing=false
        }
      }
    },
    recover(){
      this.processing=true
      this.axiosInstanceUnsecure.post('/auth/recover', this.user).then(response => {
        if(!response)
              console.log(response)
        this.processing=false
        this.$toasted.show("Un lien de récupération vous a été envoyé", {type: 'success'})
      })
      .catch(e => {
        this.processing=false
        this.$toasted.show("L'email entré n'a pas été reconnu", {type: 'error'})
        console.log(e)
      })
    }
  },
  data: function() {
     return  {
       loginMessage: "",
       message: "",
       errors: [],
       user: {},
       recoverEmail: '',
       passRecovery: null,
       processing: false
    }
  },
  mounted(){
    if(window.location.href.includes("token")){
      this.passRecovery = window.location.href.substring(window.location.href.lastIndexOf('=') + 1)
    }
  },
  components: {
    CreateUser
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .loginPart{
    margin-top: -80px;
    background-image: url('../../assets/3214.jpg');
    height:1060px;
    margin-left: -10px;
    margin-right: -10px;
  }

  .loginDiv{
    max-width: 500px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top: 25%;
  }

  .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}
</style>
